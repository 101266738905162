.BASE {
    fill: white;
    stroke: #79481d; /* Bleu Swal */
}

/* Pour simplifier et éviter la répétition, nous pouvons grouper les sélecteurs avec les mêmes styles */
.CEREALES5:hover, .CEREALES4:hover, .CEREALES3:hover, .CEREALES2:hover, .CEREALES1:hover, 
.CAFE5:hover, .CAFE4:hover, .CAFE3:hover, .CAFE2:hover, .CAFE1:hover, 
.NOISETTE5:hover, .NOISETTE4:hover, .NOISETTE3:hover, .NOISETTE2:hover, .NOISETTE1:hover, 
.CARAMEL5:hover, .CARAMEL4:hover, .CARAMEL3:hover, .CARAMEL2:hover, .CARAMEL1:hover, 
.CHOCOLAT5:hover, .CHOCOLAT4:hover, .CHOCOLAT3:hover, .CHOCOLAT2:hover, .CHOCOLAT1:hover {
    fill: #2a7de1; /* Bleu légèrement plus foncé pour le hover */
    cursor: pointer;
}

.selectedMalt {
    fill: #ffa069; /* Une couleur orange pour indiquer une sélection, pour contraster avec le bleu */
} 
