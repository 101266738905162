.header {
  display: flex;
}

@media screen and (max-width: 1024px) {
  /* Ajustez la valeur selon vos besoins */
  .header {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  /* Ajustez la valeur selon vos besoins */
  .headerMobile {
    display: none;
  }
}
