.star-rating_form {
    display: block;
    margin: auto;
    cursor: pointer;
}

.starform {
    font-size: 11vw;
    margin: 0 5px;
    color: #ffffff;
}
@media (max-width: 1024px) {
    .star-rating_form {
        display: block;
        margin: auto;
        cursor: pointer;
    }

.starform {
    font-size: 13vw;
    margin: 0 5px;
    color: #ffffff;
}
}
.starform.filled {
    color: gold;
}
