.HomePage {
  justify-self: center;
  background: #e0e5ec; /* Fond uniforme avec les autres pages */
}

.HeadHomePage {
  padding: 3%;
}

.LogoHome {
  width: 55%;
  height: 55%;
  margin-left: 25%;
  margin-right: 20%;
}

.beer-link {
  margin: 10px 0;
  background: #e0e5ec;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 5px 5px 10px #a3b1c6, -5px -5px 10px #ffffff; /* Style néomorphisme cohérent */
  width: 80%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 60px;
  overflow: hidden;
  text-decoration: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
}

.HOMEPAGEstars {
  align-items: center;
}

.HOMEPAGEstar {
  color: #bbb;
  font-size: 40px;
}

.HOMEPAGEstar.filled {
  color: #f5b301;
}

.beer-link:hover {
  transform: scale(1.03);
  box-shadow: 5px 5px 15px #a3b1c6, -5px -5px 15px #ffffff; /* Effet de hover néomorphisme */
}

.TextHomePage {
  text-align: center;
  font-size: 20px;
  font-family: "Roboto Condensed", sans-serif;
  color: #333;
}

.search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 3%;
  font-family: "Roboto Condensed", sans-serif;
}

.BeerListAndResearch {
  background: #eef0f4;
  border-radius: 40px;
  box-shadow: 8px 8px 15px #a3b1c6, -8px -8px 15px #ffffff; /* Style néomorphisme cohérent */
  margin: 3%;
  padding: 1%;
  font-family: "Roboto Condensed", sans-serif;
}

.search-bar,
.Tri {
  min-height: 30px;
  max-width: 800px;
  background: #e0e5ec;
  border-radius: 21%;
  box-shadow: 6px 6px 12px #a3b1c6, -6px -6px 12px #ffffff; /* Style néomorphisme cohérent */
  border: none;
  outline: none;
  color: #333;
  font-size: 16px;
  font-family: "Roboto Condensed", sans-serif;
}

.BeerListAndResearch ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.beer-name {
  font-weight: bold;
  color: #333;
}

.beer-brewery {
  font-style: italic;
  color: #666;
}

.search-container select,
.search-container input[type="text"] {
  border: none;
  padding: 8px 12px;
  margin: 10px;
  font-family: "Roboto Condensed", sans-serif;
}

@media (min-width: 1023px) {
  .BeerListAndResearch {
    margin-left: 150px;
  }
}
@media (max-width: 1023px) {
  .search-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-size: 2px;
    font-family: "Roboto Condensed", sans-serif;
  }

  .search-container select {
    border: 1px solid var(--input-border-color);
    border-radius: 15px;
    font-size: 20px;
    width: 30vw;
    height: 5vh;
    margin: 1vh;
    padding-left: 1vw;
    padding-right: 1vw;
    outline: none;
    background-color: var(--input-background-color);
    color: var(--input-text-color);
    transition: border-color 0.3s ease;
    cursor: pointer;
    appearance: none;
    background: var(--select-bg);
    text-align: center;
    font-family: "Roboto Condensed", sans-serif;
  }

  .search-container input[type="text"] {
    border: 1px solid var(--input-border-color);
    border-radius: 15px;
    font-size: 20px;
    width: 100vw;
    height: 5vh;
    margin: 1vh;
    outline: none;
    background-color: var(--input-background-color);
    color: var(--input-text-color);
    transition: border-color 0.3s ease;
    font-family: "Roboto Condensed", sans-serif;
  }
  .HOMEPAGEstar {
    color: #bbb;
    font-size: 30px;
  }
}

@media (min-width: 1023px) {
  .LogoHome {
    width: 8%;
    height: 8%;
    margin-left: 46%;
    margin-right: 20%;
  }
  .HeadHomePage {
    padding: 1%;
  }

  .TextHomePage {
    text-align: center;
    font-size: 20px;
    font-family: "Roboto Condensed", sans-serif;
    color: #333;
  }
}

button {
  border: none;
  padding: 20px 20px;
  margin-top: 20px;
  border-radius: 20px;
  background: #277e84;
  color: white;
  box-shadow: 5px 5px 10px #a3b1c6, -5px -5px 10px #ffffff;
  cursor: pointer;
  outline: none;
  font-family: "Roboto Condensed", sans-serif;
  margin-left: auto;
  display: block;
  transition: 0.3s;
}

button:hover {
  background: #419852;
}

.buttonMap {
  margin: auto;
}
