.foam-slider {
  align-items: center;
  display: block;
  margin: auto;
}

.FoamSlider {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  height: 35px; /* Specified height */
  border-radius: 15px;
  background: #d3d3d3; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.9; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
  margin-right: 10px;
}

/* Mouse-over effects */
.FoamSlider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.FoamSlider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: var(--input-focus-color); /* Green background */
  cursor: pointer; /* Cursor on hover */
  border-radius: 15px;
}

.FoamSlider::-moz-range-thumb {
  width: 35px; /* Set a specific slider handle width */
  height: 35px; /* Slider handle height */
  background: var(--input-focus-color); /* Green background */
  cursor: pointer; /* Cursor on hover */
  border-radius: 15px;
}
