.map-button {
  border: none;
  padding: 20px 20px;
  margin-top: 20px;
  border-radius: 20px;
  margin-left: 10px;
  background: #277e84;
  color: white;
  box-shadow: 5px 5px 10px #a3b1c6, -5px -5px 10px #ffffff;
  cursor: pointer;
  outline: none;
  font-family: "Roboto Condensed", sans-serif;
}

.map-button:hover {
  background: #419852;
}

@media (max-width: 1024px) {
  .map-button {
    display: none;
  }
}

.MapContainer {
  margin: auto;
  height: 85vh;
  width: 90vw;
  z-index: 0;
}

.SelectEffectif {
  margin: auto;
  width: 150px;
  margin-top: 20px;
  margin-bottom: 15px;
}
