.DeconnexionComponent-button {
  border: none;
  padding: 20px 20px;
  margin-top: 20px;
  border-radius: 20px;
  background: #277e84;
  color: white;
  box-shadow: 5px 5px 10px #a3b1c6, -5px -5px 10px #ffffff;
  cursor: pointer;
  outline: none;
  font-family: "Roboto Condensed", sans-serif;
  margin-left: auto;
  display: block;
}

.DeconnexionComponent-button:hover {
  background: #419852;
}

.DecoIcon {
  width: 30px;
  height: 30px;
  padding-left: 3px;
}
