  .CO7 {
     fill: #221d18; 
      }
.CO7:hover {
      opacity: 20%;
      cursor: pointer;
      }

  .CO6 {
    fill: #5c2b16;
  }
  .CO6:hover {
    opacity: 50%;
    cursor: pointer;
  }

  .CO5 {
    fill: #8c4e28;
  }
  .CO5:hover {
    opacity: 50%;
    cursor: pointer;
  }

  .CO4 {
    fill: #b56931;
  }
  .CO4:hover {
    opacity: 50%;
    cursor: pointer;
  }

  .CO3 {
    fill: #be7a3b;
  }
  .CO3:hover {
    opacity: 50%;
    cursor: pointer;
  }

  .CO2 {
    fill: #d4a557;
  }
  .CO2:hover {
    opacity: 50%;
    cursor: pointer;
  }

  .CO1 {
    fill: #f2d879;
  }
  .CO1:hover {
    opacity: 50%;
    cursor: pointer;
  }
  
  .contournoir {
    fill:rgb(2, 1, 1);
    stroke-width: 100%;
  }
  .contourblanc {
    fill:white;

  }

  .selected {
    fill: #2671ce;
  } 
