/* Styles généraux */
:root {
    --primary-color: #58626c;
    --hover-color: #2671ce;
    --border-color: #aaa;
    --background-color: #fff;
    --error-color: #f27474;
    --shadow-color: rgba(0, 0, 0, 0.15);
    --text-color: #fff;
    --input-background-color: #ffffff;
    --input-text-color: #000000;
    --input-border-color: #ddd;
    --input-focus-color: #3085d6;
    --inactive-color: #ddd;
    --label-color: #555;
}

/* Variables pour le thème sombre */
[data-theme="dark"] {
    --primary-color: #87cefa;
    --hover-color: #b0e2ff;
    --border-color: #555;
    --background-color: #333;
    --error-color: #ff7f7f;
    --shadow-color: rgba(255, 255, 255, 0.15);
    --text-color: #3f3d3d;
    --input-background-color: #444;
    --input-text-color: #ffffff;
    --input-border-color: #666;
    --input-focus-color: #87cefa;
    --inactive-color: #555;
    --label-color: #ddd;
}



.tasting-note-form {
    display: block;
    align-items: center;
    font-size: 30px;
    text-align: center;
    margin: auto;
    border: 1px solid var(--border-color);
    box-shadow: 0 0 20px var(--shadow-color);
    background-image: url('BACKGROUND.svg');
    background-repeat: no-repeat;
    background-size:cover;
    height: 99vh;
    overflow:hidden;
}

.field-container {
    flex: 0 0 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow:hidden;
    
}

.page-container {
    width: 100%;
    overflow: hidden;
    padding-bottom: 5%;    
}

.page {
    display: flex;
    transition: transform 0.8s ease;
    
}

.buttonsTastingNoteFormArea {
    display: flex;
    justify-content: space-around;
    width: 100%;
    z-index: 1;
}

.buttonsTastingNoteFormArea button {
    margin-top: 10px 10px 10px 10px;
    width: 15vw;
    padding: 1vw;
    font-size: 20px;
    margin-bottom: 1vh;
    border: none;
    background-color: var(--primary-color);
    color: var(--text-color);
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 30px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.buttonsTastingNoteFormArea svg {
    width: 5vw;
    min-width: 20px;
    height: 5vh;
}

button:hover {
    background-color: var(--hover-color);
}

.error-message {
    color: var(--error-color);
}

.step-indicator {
    display: flex;
    justify-content: center;
    margin: 25px 0;
}
.step {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--inactive-color);
    margin: 5px 3px;
    cursor: pointer;
}
.step.active {
    background-color: var(--primary-color);
}
.step.error {
    background-color: var(--error-color);
}


.hop-aroma-wheel {
    overflow: visible;
}

.segment {
    cursor: pointer;
    fill-opacity: 0.7;
}

.segment-label {
    font-size: 12px;
    fill: var(--label-color);
}

.SymboleOdorat {
    height: 20vw;
    width: 20vw;
}

svg {
    height: 60vh;
    width: 40vw;
}

input[type="date"] {
    padding: 10px;
    border: 1px solid var(--input-border-color);
    border-radius: 15px;
    font-size: 20px;
    width: 80%;
    margin: 10px;
    outline: none;
    background-color: var(--input-background-color);
    color: var(--input-text-color);
    transition: border-color 0.3s ease;
}

input[type="text"] {
    padding: 10px;
    border: 1px solid var(--input-border-color);
    border-radius: 15px;
    font-size: 20px;
    width: 80%;
    height: 100px;
    margin: 10px;
    outline: none;
    background-color: var(--input-background-color);
    color: var(--input-text-color);
    transition: border-color 0.3s ease;
}

input[type="text"]:focus,
select:focus {
    border-color: var(--input-focus-color);
    /* Focus bleu */
}


/* Styles pour tablettes et ordinateurs */

@media (max-width: 1024px) {
    .tasting-note-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 30px;
        text-align: center;
        border: 1px solid var(--border-color);
    }
    

    .buttonsTastingNoteFormArea button {
        margin-top: 15vh;
        margin-bottom: 7vh;
        border: none;
        z-index: 1;
        background-color: var(--primary-color);
        color: var(--text-color);
        cursor: pointer;
        transition: background-color 0.3s ease;
        width: 30vw;
        
    }

    .step {
        width: 4vw;
        height: 4vw;
        margin: 0 1vw;
    }

    svg {
        height: 40vh;
        width: 80vw;
        z-index: 10;
    }

}

@media (min-width: 1024px) {
    .tasting-note-form {
        width: 60%;
    }

    .step {
        width: 25px;
        height: 25px;
        margin: 0 8px;
    }
}