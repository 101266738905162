body {
  background-color: #e0e5ec;
  font-family: "Roboto Condensed", sans-serif;
}

.profile-container {
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  background: #e0e5ec;
  border-radius: 20px;
  box-shadow: 8px 8px 15px #a3b1c6, -8px -8px 15px #ffffff;
}

.profile-container h1 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.profile-container p {
  color: #333;
  line-height: 1.6;
}

.profile-container ul {
  list-style-type: none;
  padding: 0;
}

.profile-container ul li {
  background: #e0e5ec;
  margin-bottom: 10px;
  padding: 10px 15px;
  border-radius: 10px;
  box-shadow: inset 5px 5px 10px #a3b1c6, inset -5px -5px 10px #ffffff;
  color: #333;
}

.login-form {
  background: #e0e5ec;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 12px 12px 24px #a3b1c6, -12px -12px 24px #ffffff;
}

@media (max-width: 1024px) {
  .login-form {
    margin-left: 20px;
    margin-right: 20px;
  }
  .profile-container {
    margin-left: 20px;
    margin-right: 20px;
  }
  .signup-container {
    width: 70%;
  }
}

.login-form input {
  display: block;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 15px;
  border: none;
  outline: none;
  box-shadow: inset 6px 6px 6px #a3b1c6, inset -6px -6px 6px #ffffff;
  background: #e0e5ec;
  color: #333;
  font-size: 16px;
  font-family: "Roboto Condensed", sans-serif;
}

.login-form input::placeholder {
  color: #aaa;
}

.login-form input:focus {
  box-shadow: inset 3px 3px 7px #a3b1c6, inset -3px -3px 7px #ffffff;
}

.login-h1 {
  text-align: center;
}

.login-button {
  display: block;
  border: none;
  padding: 20px 20px;
  margin-top: 20px;
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
  background: #277e84;
  color: white;
  box-shadow: 5px 5px 10px #a3b1c6, -5px -5px 10px #ffffff;
  cursor: pointer;
  outline: none;
  font-family: "Roboto Condensed", sans-serif;
}

.login-button:hover {
  background: #419852;
}

/* SignUp */
.signup-container {
  background: #e0e5ec;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 12px 12px 24px #a3b1c6, -12px -12px 24px #ffffff;
}

.signup-title {
  text-align: center;
}

.signup-label {
  display: block;
  color: #333;
  font-size: 16px;
  margin-bottom: 5px;
  text-align: left;
  margin-left: 25px;
  font-family: "Roboto Condensed", sans-serif;
}

.signup-form-input {
  display: block;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 15px;
  border: none;
  outline: none;
  box-shadow: inset 6px 6px 6px #a3b1c6, inset -6px -6px 6px #ffffff;
  background: #e0e5ec;
  color: #333;
  font-size: 16px;
  font-family: "Roboto Condensed", sans-serif;
}

.signup-form-input[type="text"] {
  display: block;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  margin-bottom: 20px;
  height: 20px;
  padding: 15px;
  border-radius: 15px;
  border: none;
  outline: none;
  box-shadow: inset 6px 6px 6px #a3b1c6, inset -6px -6px 6px #ffffff;
  background: #e0e5ec;
  color: #333;
  font-size: 16px;
  font-family: "Roboto Condensed", sans-serif;
}

.signup-form-input::placeholder {
  color: #aaa;
}

.signup-form-input:focus {
  box-shadow: inset 3px 3px 7px #a3b1c6, inset -3px -3px 7px #ffffff;
}

.signup-button {
  display: block;
  border: none;
  padding: 20px 20px;
  margin-top: 20px;
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
  background: #277e84;
  color: white;
  box-shadow: 5px 5px 10px #a3b1c6, -5px -5px 10px #ffffff;
  cursor: pointer;
  outline: none;
  font-family: "Roboto Condensed", sans-serif;
}

.signup-button:hover {
  background: #419852;
}
