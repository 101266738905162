
.BASE {
    fill: white;
    stroke: #79481d; /* Bleu Swal */
}

/* Pour simplifier et éviter la répétition, nous pouvons grouper les sélecteurs avec les mêmes styles */
.SUCRE5:hover, .SUCRE4:hover, .SUCRE3:hover, .SUCRE2:hover, .SUCRE1:hover, 
.SALE5:hover, .SALE4:hover, .SALE3:hover, .SALE2:hover, .SALE1:hover, 
.AMER5:hover, .AMER4:hover, .AMER3:hover, .AMER2:hover, .AMER1:hover, 
.ACIDE5:hover, .ACIDE4:hover, .ACIDE3:hover, .ACIDE2:hover, .ACIDE1:hover, 
.UMAMI5:hover, .UMAMI4:hover, .UMAMI3:hover, .UMAMI2:hover, .UMAMI1:hover {
    fill: #2a7de1; /* Bleu légèrement plus foncé pour le hover */
    cursor: pointer;
}

.selectedGen {
    fill: #ffa069; /* Une couleur orange pour indiquer une sélection, pour contraster avec le bleu */
} 

.TEXTEWHEEL{
    fill:black;
}
