.RequiredAuth-button {
  display: block;
  border: none;
  padding: 20px 20px;
  margin-top: 20px;
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
  background: #277e84;
  color: white;
  box-shadow: 5px 5px 10px #a3b1c6, -5px -5px 10px #ffffff;
  cursor: pointer;
  outline: none;
  font-family: "Roboto Condensed", sans-serif;
}

.RequiredAuth-button:hover {
  background: #419852;
}

.RequiredAuthAlert {
  text-align: center;
  margin-top: 20px;
}
