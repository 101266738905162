
.BASE {
    fill: white;
    stroke: #79481d; /* Bleu Swal */
}

/* Pour simplifier et éviter la répétition, nous pouvons grouper les sélecteurs avec les mêmes styles */
.RESINES5:hover, .RESINES4:hover, .RESINES3:hover, .RESINES2:hover, .RESINES1:hover, 
.HERBES5:hover, .HERBES4:hover, .HERBES3:hover, .HERBES2:hover, .HERBES1:hover, 
.FLEURS5:hover, .FLEURS4:hover, .FLEURS3:hover, .FLEURS2:hover, .FLEURS1:hover, 
.EPICES5:hover, .EPICES4:hover, .EPICES3:hover, .EPICES2:hover, .EPICES1:hover, 
.AGRUMES5:hover, .AGRUMES4:hover, .AGRUMES3:hover, .AGRUMES2:hover, .AGRUMES1:hover {
    fill: #2a7de1; /* Bleu légèrement plus foncé pour le hover */
    cursor: pointer;
}

.selectedHop {
    fill: #ffa069; /* Une couleur orange pour indiquer une sélection, pour contraster avec le bleu */
} 

.TEXTEWHEEL{
    fill:black;
}
