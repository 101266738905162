@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap");

.sidebar {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #277e84;
  overflow-x: hidden;
  padding-top: 60px;
  box-shadow: 4px 4px 8px #a3b1c6, -4px -4px 8px #ffffff;
}

.sidebar-title {
  padding-top: 15px;
  display: flex;
  flex-direction: column;
}

.sidebar a {
  text-decoration: none;
  font-size: 20px;
  color: white;
  display: flex;
  padding: 15px 15px;
}

.sidebar l {
  padding-left: 10px;
  font-size: 20px;
  margin-top: 13px;
  text-transform: uppercase;
  font-family: "Roboto Condensed", sans-serif;
  color: white;
  vertical-align: middle;
}

.sidebar a:hover {
  color: rgb(223, 223, 223);
  background-color: #227075;
}

.sidebar-close-button {
  height: 35px;
  width: 35px;
  border: none;
  background: transparent;
  color: white;
  position: absolute;
  top: 25px;
  right: 22px;
  cursor: pointer;
}

.sidebar-close-button:hover {
  transform: scale(120%);
  cursor: pointer;
  color: rgb(223, 223, 223);
  background-color: #227075;
}

.SideBarIcons {
  height: 40px;
  width: 40px;
  color: white;
  padding-left: 2px;
  padding-top: 4px;
}

.ProfilArea {
  border-top-color: #227075;
  border-top-width: 2px;
  border-top-style: solid;
  margin-top: auto;
}
