@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap");

.beer-details {
  width: 70%;
  margin: auto;
  padding: 20px;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 20px;
  margin-top: 20px;
}

.beer-details h2,
.beer-details h3 {
  color: black;
  font-family: "Roboto Condensed", sans-serif;
}

.beer-details p {
  color: black; /* Texte gris foncé pour une meilleure lisibilité sur fond clair */
  line-height: 1.6;
  align-self: center;
  font-size: 20px;
  font-family: "Roboto Condensed", sans-serif;
}

.beer-details .supprimer {
  display: inline-block;
  background-color: #277e84;
  color: white;
  padding: 10px 20px;
  margin-top: 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.5s ease, color 0.5s ease;
  font-family: "Roboto Condensed", sans-serif;
}

.beer-details .supprimer:hover {
  background-color: #419852;
  color: #fff;
}

.beer-details svg {
  width: 200px;
  height: 200px;
}

.star {
  font-size: 50px;
}
.star {
  color: #bbb;
}

.star.filled {
  color: #f5b301;
}

.RenderCarbo {
  width: 10%;
  height: 10%;
}
.RenderFoam {
  max-width: 10%;
  max-height: 10%;
}

.RenderBeerColor {
  svg {
    width: 32%;
    height: 32%;
  }

  .NOIRE {
    fill: #221d18;
  }

  .BRUNE {
    fill: #5c2b16;
  }

  .CUIVREE {
    fill: #8c4e28;
  }

  .ROUSSE {
    fill: #b56931;
  }

  .AMBREE {
    fill: #be7a3b;
  }

  .BLONDE {
    fill: #d4a557;
  }

  .PAILLE {
    fill: #f2d879;
  }

  .selectionCouleur {
    fill: red;
  }
}

.date-container {
  font-size: 110%;
  font-family: "Roboto Condensed", sans-serif;
}

.selectedHopAroma,
.SelectedMaltAroma,
.selectedHopFlavor,
.selectedMaltFlavor,
.selectedGenFlavor {
  fill: #419852;
}

@media (max-width: 1024px) {
  .beer-details p {
    text-align: center;
    font-size: 20px;
    color: #333;
  }

  .beer-details svg,
  .beer-details .RenderCarbo,
  .beer-details .RenderBeerColorSVG {
    display: block;
    margin: auto;
    max-width: 100%;
  }

  .beer-details svg {
    width: 45vw;
    height: 45vw;
  }

  .beer-details {
    width: 30vw;
    height: 30vw;
  }

  .beer-details .RenderBeerColorSVG {
    width: 70vw;
    height: 70vw;
  }

  .RenderFoam {
    max-width: 35%;
    max-height: 35%;
  }
  .RenderCarbo {
    width: 80%;
    height: 25%;
  }
}

@media (min-width: 1024px) {
  .beer-details {
    width: 70%;
    margin: auto;
    padding: 20px;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Ombre douce pour donner de la profondeur */
    display: flex;
    flex-direction: column;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .ZoneVue {
    display: flex;
    flex-direction: row;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 20px;
    padding: 1vw;
    justify-content: space-around;
    align-items: center;
    margin: 1vw 1vw 1vw 1vw;
    font-size: 30px;
    box-shadow: 0 0 0 6px #277e84 inset;
  }

  .ZoneOdorat {
    display: flex;
    flex-direction: row;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.4);
    padding: 1vw;
    border-radius: 20px;
    justify-content: space-around;
    align-items: center;
    margin: 1vw 1vw 1vw 1vw;
    font-size: 30px;
    box-shadow: 0 0 0 6px #277e84 inset;
  }

  .ZoneGout {
    display: flex;
    flex-direction: row;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 20px;
    padding: 1vw;
    justify-content: space-around;
    align-items: center;
    margin: 1vw 1vw 1vw 1vw;
    font-size: 30px;
    box-shadow: 0 0 0 6px #277e84 inset;
  }

  .ZoneGen {
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 20px;
    padding: 1vw;
    justify-content: space-around;
    margin: 1vw 1vw 1vw 1vw;
    font-size: 30px;
    box-shadow: 0 0 0 6px #277e84 inset;
  }

  .ZoneDetailText {
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 20px;
    padding: 1vw;
    justify-content: space-around;
    margin: 1vw 1vw 1vw 1vw;
    font-size: 30px;
    box-shadow: 0 0 0 6px #277e84 inset;
  }

  .date-container {
    text-align: center;
    font-size: 30px;
    text-decoration: underline;
    color: rgb(19, 19, 19);
  }
  .beer-details h2 {
    text-align: center;
    font-size: 30px;
    text-decoration: underline;
    color: rgb(19, 19, 19);
  }
  .beer-details h3 {
    text-align: center;
    font-size: 30px;
    text-decoration: underline;
    color: rgb(19, 19, 19);
  }
}
