.icon {
    height: 4vw;
    width: 4vw;
    cursor: pointer;
 }

 @media (max-width: 1024px) {
    .icon {
        height: 12vh;
        width: 12vw;
        cursor: pointer;
     }

 }
